import { useEffect } from "react";
import logo from "../../assets/logo-tha.png";

const FormThankyou = (props) => {
  useEffect(() => {
    setTimeout(() => {
      handleBack()
    }, 0.5 * 1000);
  }, []);

  const handleBack = () => {
    window.location.href = "./checkin";
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center  px-5 py-5 bg-white bg-opacity-80 backdrop-blur-2xl rounded-md w-full md:w-1/2">
        
        <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
          ADDED USER SUCCESS
        </div>

        {/* <div className="mx-auto flex justify-center items-center">
          <button
            type="button"
            onClick={() => handleBack()}
            className={`w-[160px] mt-10 px-10 py-2 rounded-lg text-white font-bold border-b-4 border-blue-700 bg-blue-500  text-center`}
           >
            OK
          </button>
        </div> */}
      </div>
    </>
  );
};

export default FormThankyou;
